<template>
  <b-modal
    id="examenes-modal"
    title="Lista de examenes"
    title-class="text-white"
    header-class="bg-danger"
    hide-footer
    size="xl"
    @hide="resetInfoModal"
  >
    <!-- <template #modal-header="{ close }">
      <h4 class="text-white px-0 mx-0">
        Lista de atenciones
      </h4>
      <b-button
        variant="danger"
        size="sm"
        @click="close()"
      >
        <span>x</span>
      </b-button>
    </template> -->

    <b-row align-h="between">
      <b-col
        cols="12"
        md="4"
        class="mb-md-0 mb-2"
      >
        <label>Desde - Hasta</label>
        <flat-pickr
          v-model="desdeHastaFilter"
          class="form-control form-control-sm"
          :config="{ mode: 'range', altInput: true, altFormat: 'd/m/Y', dateFormat: 'Y-m-d', defaultDate: new Date()}"
          @input="actualizarTabla"
        />
      </b-col>

      <b-col
        cols="12"
        md="4"
        class="mb-md-0 mb-2"
      >
        <label>Servicio</label>
        <v-select
          v-model="servicioFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="servicioOptions"
          class="w-100 select-size-sm"
          :reduce="val => val.value"
          @input="actualizarTabla"
        />
      </b-col>
    </b-row>
    <!-- <div class="m-2"> -->

    <!-- Table Top -->
    <b-row class="mt-1">

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Mostrar</label>
        <v-select
          v-model="perPage"
          :options="pageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50 select-size-sm"
        />
        <label>registros</label>
      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="filter"
            type="search"
            size="sm"
            class="d-inline-block mr-1"
            placeholder="Buscar..."
            debounce="500"
          />
        </div>
      </b-col>
    </b-row>

    <!-- </div> -->

    <b-table
      ref="refAtencionesLista"
      class="position-relative mt-1"
      responsive
      bordered
      hover
      small
      show-empty
      :filter="filter"
      :fields="fields"
      :busy.sync="isBusy"
      :per-page="perPage"
      :items="myProvider"
      :empty-text="'No hay registros para mostrar'"
      :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template
            #button-content
          >
            <feather-icon
              icon="MoreHorizontalIcon"
              size="15"
              class="align-middle text-body"
            />
          </template>
          <!-- <b-dropdown-item
            v-b-modal.modal_historia_clinica
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item> -->

          <b-dropdown-item
            :to="row.item.url"
            target="_blank"
          >
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">Ver detalles</span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>

    <!-- <pre>{{ historia.content }}</pre> -->
  </b-modal>
</template>

<script>
import {
  // BButton,
  BTable, BRow, BCol, BSpinner, BPagination, BFormInput,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import { mapState } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import useStoreHistoriaClinica from '../useStoreHistoriaClinica'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    vSelect,
    // BButton,
    BSpinner,
    flatPickr,
    BFormInput,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  props: {
    usuarioId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      desdeHastaFilter: '',
      servicioFilter: null,
      servicioOptions: [],
      items: [],
      fields: [
        { key: 'titulo', label: 'titulo' },
        { key: 'servicio.nombre', label: 'servicio' },
        { key: 'created_at', label: 'fecha registro' },
        { key: 'actions', label: 'acción' },
      ],
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'desc',
      filter: '',
    }
  },
  computed: {
    ...mapState('historias', ['historia_id', 'usuario_id']),
  },
  watch: {
    desdeHastaFilter(newValue, oldValue) {
      console.log(newValue, oldValue)
    },
  },
  created() {
    store.dispatch('historias/fetchServicios')
      .then(response => {
        this.servicioOptions = response.data.data.map(item => {
          const i = { value: item.id, label: item.nombre }
          return i
        })
      })
  },
  methods: {
    actualizarTabla() {
      this.$refs.refAtencionesLista.refresh()
    },
    resetInfoModal() {
    },
    async myProvider(ctx) {
      // eslint-disable-next-line no-param-reassign
      ctx.desde = this.desdeHastaFilter.substring(0, 10)
      // eslint-disable-next-line no-param-reassign
      ctx.hasta = this.desdeHastaFilter.substring(14, 24)
      // eslint-disable-next-line no-param-reassign
      ctx.servicioFilter = this.servicioFilter
      store.commit('historias/setUsuarioId', this.usuarioId)
      const promise = store.dispatch('historias/fetchExamenes', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response

        const items = data
        // const items = data.map(item => {
        //   const { usuario, ...rest } = item
        //   const nuevoItem = { ...rest }
        //   nuevoItem.persona = item.usuario.perfil.persona
        //   return nuevoItem
        // })

        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0

        this.to = to || 0
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
  },
  setup() {
    const TRIAJES_APP_STORE_MODULE_NAME = 'historias'

    // Register module
    if (!store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.registerModule(TRIAJES_APP_STORE_MODULE_NAME, useStoreHistoriaClinica)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.unregisterModule(TRIAJES_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
