<template>
  <b-modal
    id="modal_historia_clinica"
    header-bg-variant="warning"
    hide-footer
    centered
  >
    <template #modal-header>
      <strong class="text-white">Agregar número de historia clinica</strong>
    </template>
    <h4 class="text-center text-primary mt-1 mb-1">
      <strong>PACIENTE:</strong> {{ paciente.nombre_completo }} - <span class="text-danger">{{ paciente.numero_documento === '' ? 'sin documento' : paciente.numero_documento }}</span>
    </h4>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <validation-provider
          #default="validationContext"
          name="n_historia_clinica"
          rules="required"
        >
          <b-form-group label="N° de historia clinica">
            <b-form-input
              v-model="paciente.historia_clinica"
              placeholder="."
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              Complete el campo fecha inicio
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- <validation-provider
          #default="validationContext"
          name="n_celular"
          rules="required"
        >
          <b-form-group label="N° celular">
            <b-form-input
              v-model="celular"
              placeholder="."
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              Complete el campo celular
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="direccion"
          rules="required"
        >
          <b-form-group label="Dirección">
            <b-form-input
              v-model="direccion"
              placeholder="."
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              Complete el campo direccion
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider> -->
        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="$bvModal.hide('modal_historia_clinica')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            <feather-icon
              icon="SaveIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    paciente: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  // data() {
  //   return {
  //     historiaClinica: null,
  //     // celular: null,
  //     // direccion: null,
  //   }
  // },
  methods: {
    onSubmit() {
      const data = {
        id: this.paciente.historia_clinica_id,
        // usuario_id: this.paciente.usuario_id,
        numero_historia_clinica: this.paciente.historia_clinica,
      }
      // console.log(data)
      if (this.paciente.historia_clinica_id) {
        store.dispatch('historias/updateHistoriaClinica', data)
          .then(() => {
            this.toastSuccess()
            this.$emit('update-numero_historia_clinica')
            this.$bvModal.hide('modal_historia_clinica')
          })
          .catch(error => {
            this.toastError(`${error.response.data.error.numero_historia_clinica[0]}`)
          })
      } else {
        store.dispatch('historias/addHistoriaClinica', data)
          .then(() => {
            this.toastSuccess()
            this.$emit('update-numero_historia_clinica')
            this.$bvModal.hide('modal_historia_clinica')
          })
          .catch(error => {
            this.toastError(`${error.response.data.error}`)
          })
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
